// const routes = [
//   {
//     path: ["/", "/home"],
//     exact: true,
//     component: "Home",
//   },
//   {
//     path: ["/register", "/register/:referralID",  "/login"],
//     exact: true,
//     component: "Auth",
//   },

//   {
//     path: ["/redeem"],
//     exact: true,
//     component: "Redeem",
//   },
//   {
//     path: ["vendor/:uid"],
//     exact: true,
//     component: "OrderFlow",
//   },
//   {
//     path: ["/recipe/:recipeId"],
//     exact: true,
//     component: "RecipeDetails",
//   },
//   {
//     path: ["/admin/"],
//     exact: true,
//     component: "Admin",
//   },
//   {
//     path: ["/privacy/"],
//     exact: true,
//     component: "Privacy",
//   },
  
//   {
//     path: ["/contact/"],
//     exact: true,
//     component: "Contact us",
//   },
  
//   {
//     path: ["/pricing/"],
//     exact: true,
//     component: "Pricing",
//   },
//   {
//     path: ["/edit/:username"],
//     exact: true,
//     component: "Edit",
//   },
// ];

// export default routes;

const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/register", "/register/:referralID", "/login"],
    exact: true,
    component: "Auth",
  },
  {
    path: "/redeem",
    exact: true,
    component: "Redeem",
  },
  {
    path: "/vendor/:uid",
    exact: true,
    component: "OrderFlow",
  },
  {
    path: "/recipe/:recipeId",
    exact: true,
    component: "RecipeDetails",
  },
  {
    path: "/admin",
    exact: true,
    component: "Admin",
  },
  {
    path: "/privacy",
    exact: true,
    component: "Privacy",
  },
  {
    path: "/contact",
    exact: true,
    component: "Contact us",
  },
  {
    path: "/pricing",
    exact: true,
    component: "Pricing",
  },
  {
    path: "/edit/:username",
    exact: true,
    component: "Edit",
  },
];

export default routes;